<template>
  <v-layout class="flex-column white pt-3 pb-6">
    <div class="flex-y-center flex-justify-between pb-2 px-4" style="border-bottom:1px solid #eee;">
      <div>
        <v-btn dark small color="#00bea9" depressed min-width="32" height="30" style="padding:0 8px;" @click="addShow">
          <v-icon size="16">mdi-plus</v-icon>
          新增
        </v-btn>
      </div>
      <div class=" flex-y-center flex-wrap">
        <div class="flex-y-center mr-1" style="width:120px;">
          <form-item v-model="search.data.showVersionNumber" class="mr-0" placeholder="版本号" type="text" allow-close @onEnter="initList()"/>
        </div>
        <div class="flex-y-center mr-1" style="width:120px;">
          <form-item v-model="search.data.statusFlag" :options="[{text:'可用',value:'0'},{text:'禁用',value:'1'}]" class="mr-0" placeholder="状态" type="select" allow-close @onEnter="initList()"/>
        </div>
        <v-btn dark small :loading="listLoading" color="primary" class="mr-1" depressed min-width="34" height="30" style="padding:0;" @click="initList">
          <v-icon size="18">mdi-magnify</v-icon>
        </v-btn>
        <v-btn dark small color="grey lighten-1" :loading="listLoading" depressed min-width="34" height="30" style="padding:0;" @click="search.data={},initList()">
          <v-icon size="18">mdi-restore</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width pa-4"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
        :loading="listLoading"
      >
        <!-- <template v-slot:item="{expand,item}"> -->

        <template v-slot:item.rowIndex="{index}">
          {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
        </template>
        <template v-slot:item.systemModule="{item}">
          {{ item.systemModule==1 ? '管理后台' : '业务前台' }}
        </template>
        <template v-slot:item.statusFlag="{item}">
          <v-chip v-if="item.statusFlag==1" small dark color="red" label>禁用</v-chip>
          <v-chip v-else small dark color="green" label>启用</v-chip>
        </template>
        <template v-slot:item.active="{item}">
          <v-icon v-if="item.statusFlag==0" color="primary" size="18" title="编辑" class=" mr-1" @click="showEdit(item)">mdi-square-edit-outline</v-icon>
          <v-icon v-if="item.statusFlag==1" color="primary" size="18" class=" mr-1" title="恢复" @click="activeList($api.sysVersionEnable,'post','恢复',{id:item.id,statusFlag:'0'})">mdi-replay</v-icon>
          <v-icon v-else color="error" size="18" class=" mr-1" title="禁用" @click="activeList($api.sysVersionDisabled,'post','禁用',{id:item.id,statusFlag:'1'})">mdi-cancel</v-icon>
          <v-icon color="primary" title="详情" size="18" @click="loadInfo(item)">mdi-information-outline</v-icon>
          <!--          <v-icon color="error" size="20" @click="activeList($api.sysVersionDelete,'delete','删除',{id:item.id,statusFlag:'0'})" title="删除" class=" mr-1">mdi-delete-outline</v-icon>-->
        </template>
      </v-data-table>
      <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
        <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getList()" @changePageSize="changePageSize" />
      </div>
    </div>
    <!-- 新增 编辑 版本 -->
    <v-dialog v-model="dialog.show" width="1000">
      <form @submit.prevent="submitAdd()">
        <v-card>
          <v-card-title class="font-weight-bold">{{ dialog.id?'编辑版本':'新增版本' }}</v-card-title>
          <v-divider />
          <v-card-text class=" py-5">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>版本号：</div>
                <input v-model="dialog.data.showVersionNumber" class="flex-1 default-input py-1 subtitle-1" placeholder="请输入..." required type="text">
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>版本号：</div>
                <form-item v-model="dialog.data.systemModule" :options="[{text:'管理后台',value:1},{text:'业务前台',value:2}]" placeholder="请选择..." dense type="radio" />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>更新日期：</div>
                <form-item v-model="dialog.data.versionUpdateTime" placeholder="版本更新时间" dense type="date" />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>新特性/改进/BUG修复：</div>
                <div class="flex-1" style="border: 1px solid #ccc">
                  <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :default-config="toolbarConfig" :mode="mode" />
                  <Editor v-model="dialog.data.content" :default-config="editorConfig" style="height: 200px; overflow-y:auto;" :mode="mode" @onCreated="onCreated" />
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" height="30" class depressed @click="dialog.show = false">取消</v-btn>
                <v-btn
                    width="80"
                    :loading="btnLoading"
                    type="submit"
                    class="ml-3"
                    height="30"
                    color="primary"
                    depressed
                >确定</v-btn>

              </div>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 新增 编辑 版本 end -->

    <!-- 详情 版本 -->
    <v-dialog v-model="info.show" width="1000">
      <v-card>
        <v-card-title class="font-weight-bold">版本更新详情</v-card-title>
        <div class=" flex-1 overflow-hidden pt-8  mb-1 px-4" style="">
          <div class=" flex-0 mb-5 flex-y-center">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end">版本号：</div>
            <div>{{ info.data.showVersionNumber }}</div>
          </div>
          <div class=" flex-0 mb-5 flex-y-center">
            <div class="form-title flex-0 flex-shrink-0 flex-justify-end">更新日期：</div>
            <div>{{ info.data.versionUpdateTime }}</div>
          </div>
          <v-divider />
          <div class="pa-5 version-editor" v-html="info.data.content" />
        </div>
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" height="30" class depressed @click="info.show = false">取消</v-btn>
              <v-btn width="80" @click="info.show = false" class="ml-3" height="30" color="primary" depressed>确定</v-btn>

            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 详情 版本 end -->
  </v-layout>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '版本号', value: 'showVersionNumber' },
        { text: '系统模块', value: 'systemModule', sortable: false },
        { text: '更新日期', value: 'versionUpdateTime', sortable: false },
        { text: '最后修改时间', value: 'createTime', sortable: false },
        { text: '状态', value: 'statusFlag', sortable: false },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      listLoading: false,
      btnLoading: false,
      editor: null,
      toolbarConfig: {
        toolbarKeys: [
          'fontFamily', 'headerSelect', 'bold', 'underline', 'italic', 'bgColor', 'fontSize', 'lineHeight', 'justifyLeft', 'justifyRight', 'justifyCenter', 'insertLink', 'unLink', 'uploadImage' // 重复
        ]
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: this.$api.settingUploadImage,
            // config.headers['X-Access-Token'] = localStorage.getItem('access_token') || ''
            headers: {
              'X-Access-Token': localStorage.getItem('access_token') || ''
            },
            fieldName: 'file',
            maxFileSize: 50 * 1024 * 1024, // 50M
            // 单个文件上传成功之后
            onSuccess(file, res) { // JS 语法
              // console.log(`${file.name} 上传成功`, res)
            },

            // 单个文件上传失败
            onFailed(file, res) { // JS 语法
              window.vm_app.$message.error('上传失败！')
              // console.log(`${file.name} 上传失败`, res)
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) { // JS 语法
              window.vm_app.$message.error('上传失败！')
              // console.log(`${file.name} 上传出错`, err, res)
            },
            // 自定义插入图片
            customInsert(res, insertFn) { // JS 语法
              // res 即服务端的返回结果
              // 从 res 中找到 url alt href ，然后插入图片
              // console.log(res)
              // insertFn(url, alt, href)replace
              insertFn(res.result, res.result, res.result)
            }
          },
          fontFamily: {
            fontFamilyList: [
              '黑体', '楷体', { name: '仿宋', value: '仿宋' }, 'Arial', 'Tahoma', 'Verdana'
            ]
          },
          fontSize: {
            fontSizeList: [
              '12px', '14px', '16px', '20px', '24px', '28px', '32px', '48px', '72px'
            ]
          },
          lineHeight: {
            lineHeightList: ['1', '1.5', '2', '2.5']
          }
        }
        // MENU_CONF['uploadImage']: {
        //     server: '/api/upload',
        // }
      },
      mode: 'default', // or 'simple'
      dialog: {
        show: false,
        data: {}
      },
      info: {
        show: false,
        data: {}
      }
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      this.search.pageNo = 1
      this.getList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getList()
    },
    getList() {
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageSize = this.search.pageSize
      search.pageNo = this.search.pageNo
      this.$http.get(this.$api.sysVersionQueryPageList, { data: { ...search }}).then(res => {
        this.listLoading = false
        this.list = res.result.records || []
        this.total = res.result.total
      })
    },
    activeList(api, method, title, par) {
      this.$confirm({ text: '是否确认' + title + '版本？' }).then((vm) => {
        // const param = new URLSearchParams()
        // for (const i in par) {
        //   param.append(i, par[i] || '')
        // }
        vm.loading = true
        this.$http[method](api, { data: par }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.initList()
          location.reload()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 编辑弹窗
    showEdit(item) {
      this.dialog.show = true
      this.dialog.data = { ...item }
      // this.dialog.data.tagColor
      // this.dialog.color={hex:this.dialog.data.tagColor||''}
      // this.dialog.color = this.dialog.data.tagColor || '#D53434'
      // this.dialog.color=this.dialog.data.tagColor||""
    },
    addShow(item) {
      this.dialog.show = true
      this.dialog.data = { ...item }
    },
    submitAdd() {
      let url = this.$api.sysVersionAdd
      if (this.dialog.data.id) {
        url = this.$api.sysVersionEdit
      }
      // this.dialog.data.tagColor=this.dialog.color.hex||''
      // this.dialog.data.tagColor=this.dialog.color||''
      this.btnLoading = true
      this.$http.post(url, { data: { ...this.dialog.data }}).then(res => {
        this.$message.success(res.message)
        this.dialog.show = false
        location.reload()
        // this.dialog.data = {}
        // this.btnLoading = false
        this.getList()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    loadInfo(item) {
      this.$http.get(this.$api.sysVersionQueryById, { data: { id: item.id }}).then(res => {
        this.info.show = true
        this.info.data = res.result || {}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.form-title{
  width:100px;
  color: #777;
}
</style>
<style lang="scss">
.version-editor{
  img{
    max-width: 100% !important;
  }
}
</style>
